import {
  Box,
  Typography,
  Modal,
  TextField,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Divider,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { useState, useEffect } from "react";

const carMakes = [
  "Ford",
  "Chevrolet",
  "Toyota",
  "SUZUKI",
  "Honda",
  "Nissan",
  "BMW",
  "Mercedes-Benz",
  "Volkswagen",
  "Audi",
  "Hyundai",
  "Kia",
  "Subaru",
  "Mazda",
  "Lexus",
  "Jaguar",
  "Land Rover",
  "Porsche",
  "Ferrari",
  "Lamborghini",
  "Tesla",
  "Others",
];

const fuelTypes = ["ELECTRIC", "HYBRID", "GASOLINE", "DIESEL", "PETROL"];

export interface CarDetailsPayload {
  car_make: string;
  car_model: string;
  car_year: string;
  fuel_type: string;
  license_plate: string;
  total_seats: number;
  ac_working: boolean;
  delivery_capacity: {
    max_height: number;
    max_width: number;
    max_length: number;
    max_weight: number;
  };
  // vehicle_type: string;  // Commented out vehicle_type from interface
}

interface CarInfoModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: CarDetailsPayload) => void;
  initialValues?: Partial<CarDetailsPayload>;
}

interface FormErrors {
  car_make?: string;
  car_model?: string;
  car_year?: string;
  fuel_type?: string;
  license_plate?: string;
  total_seats?: string;
  // vehicle_type?: string;  // Commented out vehicle_type from errors
  customCarMake?: string;
  max_height?: string;
  max_width?: string;
  max_length?: string;
  max_weight?: string;
}

const CarInfoModal: React.FC<CarInfoModalProps> = ({ open, onClose, onSave, initialValues }) => {
  const [carInfo, setCarInfo] = useState<CarDetailsPayload>({
    car_make: "",
    car_model: "",
    car_year: "",
    fuel_type: "",
    license_plate: "",
    total_seats: 1,
    ac_working: false,
    delivery_capacity: {
      max_height: 0,
      max_width: 0,
      max_length: 0,
      max_weight: 0,
    },
    // vehicle_type: "",  // Commented out vehicle_type from initial state
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [customCarMake, setCustomCarMake] = useState("");

  useEffect(() => {
    if (initialValues) {
      setCarInfo((prev) => ({
        ...prev,
        ...initialValues,
        delivery_capacity: {
          ...prev.delivery_capacity,
          ...initialValues.delivery_capacity,
        },
      }));
      if (
        initialValues.car_make &&
        !carMakes.includes(initialValues.car_make as (typeof carMakes)[number])
      ) {
        setCustomCarMake(initialValues.car_make);
      }
    }
  }, [initialValues]);

  const validate = (): boolean => {
    const newErrors: FormErrors = {};

    // Validate required fields
    if (!carInfo.car_make) newErrors.car_make = "Car make is required";
    if (carInfo.car_make === "Others" && (!customCarMake || !customCarMake.trim())) {
      newErrors.customCarMake = "Please specify car make";
    }
    if (!carInfo.car_model || !carInfo.car_model.trim())
      newErrors.car_model = "Car model is required";
    if (!carInfo.car_year) {
      newErrors.car_year = "Car year is required";
    } else if (!/^(19|20)\d{2}$/.test(carInfo.car_year)) {
      newErrors.car_year = "Enter a valid year (1900-2099)";
    }
    if (!carInfo.fuel_type) newErrors.fuel_type = "Fuel type is required";
    if (!carInfo.license_plate || !carInfo.license_plate.trim())
      newErrors.license_plate = "License plate is required";
    if (!carInfo.total_seats && carInfo.total_seats !== 0) {
      newErrors.total_seats = "Total seats is required";
    } else if (carInfo.total_seats < 1 || carInfo.total_seats > 20) {
      newErrors.total_seats = "Must be between 1 and 20";
    }
    // if (!carInfo.vehicle_type) newErrors.vehicle_type = "Vehicle type is required";  // Commented out vehicle_type validation

    // Validate delivery capacity
    Object.entries(carInfo.delivery_capacity).forEach(([key, value]) => {
      const capacityKey = key as keyof typeof carInfo.delivery_capacity;
      if (value === null || value === undefined || value.toString() === "") {
        newErrors[capacityKey] = "This field is required";
      } else if (value < 0) {
        newErrors[capacityKey] = "Must be a positive number";
      } else if (capacityKey !== "max_weight" && value > 1000) {
        newErrors[capacityKey] = "Value too large";
      } else if (capacityKey === "max_weight" && value > 5000) {
        newErrors[capacityKey] = "Weight too large";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCarInfo((prev) => ({ ...prev, [name]: value }));
    // Clear error when user starts typing
    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setCarInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCarInfo((prev) => ({ ...prev, [name]: checked }));
  };

  const handleDeliveryCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numValue = value === "" ? "" : Number(value);
    setCarInfo((prev) => ({
      ...prev,
      delivery_capacity: {
        ...prev.delivery_capacity,
        [name]: numValue,
      },
    }));
    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSave = () => {
    if (validate()) {
      const finalCarInfo: CarDetailsPayload = {
        ...carInfo,
        car_make: carInfo.car_make === "Others" ? customCarMake : carInfo.car_make,
        total_seats: Number(carInfo.total_seats),
        delivery_capacity: {
          max_height: Number(carInfo.delivery_capacity.max_height),
          max_width: Number(carInfo.delivery_capacity.max_width),
          max_length: Number(carInfo.delivery_capacity.max_length),
          max_weight: Number(carInfo.delivery_capacity.max_weight),
        },
      };
      onSave(finalCarInfo);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Car Details
        </Typography>

        <Grid container spacing={2}>
          {/* Row 1 */}
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.car_make}>
              <FormLabel>Car Make</FormLabel>
              <Select name="car_make" value={carInfo.car_make} onChange={handleSelectChange}>
                {carMakes.map((make) => (
                  <MenuItem key={make} value={make}>
                    {make}
                  </MenuItem>
                ))}
              </Select>
              {errors.car_make && (
                <Typography color="error" variant="caption">
                  {errors.car_make}
                </Typography>
              )}
            </FormControl>
            {carInfo.car_make === "Others" && (
              <TextField
                label="Enter Car Make"
                value={customCarMake}
                onChange={(e) => {
                  setCustomCarMake(e.target.value);
                  if (errors.customCarMake) {
                    setErrors((prev) => ({ ...prev, customCarMake: undefined }));
                  }
                }}
                fullWidth
                sx={{ mt: 2 }}
                error={!!errors.customCarMake}
                helperText={errors.customCarMake}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.fuel_type}>
              <FormLabel>Fuel Type</FormLabel>
              <Select name="fuel_type" value={carInfo.fuel_type} onChange={handleSelectChange}>
                {fuelTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {errors.fuel_type && (
                <Typography color="error" variant="caption">
                  {errors.fuel_type}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Car Model"
              name="car_model"
              value={carInfo.car_model}
              onChange={handleChange}
              fullWidth
              error={!!errors.car_model}
              helperText={errors.car_model}
            />
          </Grid>

          {/* Row 2 */}
          <Grid item xs={6}>
            <TextField
              label="Car Year"
              name="car_year"
              value={carInfo.car_year}
              onChange={handleChange}
              fullWidth
              error={!!errors.car_year}
              helperText={errors.car_year}
            />
          </Grid>

          {/* Row 3 */}
          <Grid item xs={6}>
            <TextField
              label="License Plate"
              name="license_plate"
              value={carInfo.license_plate}
              onChange={handleChange}
              fullWidth
              error={!!errors.license_plate}
              helperText={errors.license_plate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total Seats"
              name="total_seats"
              type="number"
              value={carInfo.total_seats}
              onChange={handleChange}
              fullWidth
              inputProps={{ min: 1, max: 20 }}
              error={!!errors.total_seats}
              helperText={errors.total_seats}
            />
          </Grid>

          {/* Row 4 - Commented out vehicle type section */}
          {/* <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.vehicle_type}>
                <FormLabel>Vehicle Type</FormLabel>
                <Select
                  name="vehicle_type"
                  value={carInfo.vehicle_type}
                  onChange={handleSelectChange}
                >
                  {Object.entries(vehicleTypes).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.vehicle_type && (
                  <Typography color="error" variant="caption">
                    {errors.vehicle_type}
                  </Typography>
                )}
              </FormControl>
            </Grid> */}
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={carInfo.ac_working}
                  onChange={handleCheckboxChange}
                  name="ac_working"
                />
              }
              label="AC Working"
            />
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Delivery Capacity
            </Typography>
          </Grid>

          {/* Delivery Capacity Row 1 */}
          <Grid item xs={6}>
            <TextField
              label="Max Height (cm)"
              name="max_height"
              type="number"
              value={carInfo.delivery_capacity.max_height}
              onChange={handleDeliveryCapacityChange}
              fullWidth
              error={!!errors.max_height}
              helperText={errors.max_height}
              InputProps={{
                endAdornment: <Typography variant="caption">cm</Typography>,
                inputProps: { min: 0, max: 1000 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Max Width (cm)"
              name="max_width"
              type="number"
              value={carInfo.delivery_capacity.max_width}
              onChange={handleDeliveryCapacityChange}
              fullWidth
              error={!!errors.max_width}
              helperText={errors.max_width}
              InputProps={{
                endAdornment: <Typography variant="caption">cm</Typography>,
                inputProps: { min: 0, max: 1000 },
              }}
            />
          </Grid>

          {/* Delivery Capacity Row 2 */}
          <Grid item xs={6}>
            <TextField
              label="Max Length (cm)"
              name="max_length"
              type="number"
              value={carInfo.delivery_capacity.max_length}
              onChange={handleDeliveryCapacityChange}
              fullWidth
              error={!!errors.max_length}
              helperText={errors.max_length}
              InputProps={{
                endAdornment: <Typography variant="caption">cm</Typography>,
                inputProps: { min: 0, max: 1000 },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Max Weight (kg)"
              name="max_weight"
              type="number"
              value={carInfo.delivery_capacity.max_weight}
              onChange={handleDeliveryCapacityChange}
              fullWidth
              error={!!errors.max_weight}
              helperText={errors.max_weight}
              InputProps={{
                endAdornment: <Typography variant="caption">kg</Typography>,
                inputProps: { min: 0, max: 5000 },
              }}
            />
          </Grid>

          {/* Save Button */}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CarInfoModal;
