import { VtcStatus } from "../types/users";

export const formatTextToCapitalise = (status?: VtcStatus | null) => {
  if (!status || status === undefined) return "-";
  return status
    .split("_") // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join with spaces
};

export const formatTime = (isoString: string) => {
  const date = new Date(isoString);

  // Format the date and time based on the device's locale and options
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Change this to false if you want a 24-hour format
  });
};

export const carTypes = new Map([
  ["CLASSIC", "Classic"],
  ["GREEN", "Green"],
  ["VAN", "Van"],
  ["MOTOR_BIKE", "Motor Bike"],
  ["TRIKE", "Trike/Van..."],
  ["MEDIUM_TRUCK", "Medium Truck (< 1.5 ton)"],
  ["LARGE_TRUCK", "Large Truck (> 1.5 Ton)"],
]);

export const vehicleTypes = {
  CAR: "Car",
  MOTOR_BIKE: "Motor Bike",
  TRIKE: "Trike/Van...",
  MEDIUM_TRUCK: "Medium Truck (< 1.5 ton)",
  LARGE_TRUCK: "Large Truck (> 1.5 Ton)",
};
