import { GET_DELIVERY_CONFIGS, UPDATE_DELIVERY_CONFIG } from "./../actionTypes/actionTypes";
import { GET_COUNTRIES, GET_CITIES } from "../actionTypes/actionTypes";
import { CountryData, CityData, ConfigsData, DeliveryConfigType } from "../types/earnings";

interface StateType {
  countries: CountryData[];
  cities: CityData[];
  configs: ConfigsData[];
  config: DeliveryConfigType;
}

const INITIAL_STATE: StateType = {
  countries: [],
  cities: [],
  configs: [],
  config: {
    country_code: "",
    region_code: "",
    normal_driver_comission: 0,
    premium_driver_comission: 0,
    cancelled_ride_comission: 0,
    community_driver_comission_within_six_months: 0,
    community_driver_comission_after_six_months: 0,
    ride_cancellation_charges: 0,
    scheduler: 0,
    created_at: "",
    is_archived: false,
  },
};

const earningsReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRIES:
      return Object.assign({}, state, { countries: payload });
    case GET_CITIES:
      return Object.assign({}, state, { cities: payload });
    case GET_DELIVERY_CONFIGS:
      return Object.assign({}, state, { configs: payload });
    case UPDATE_DELIVERY_CONFIG:
      return Object.assign({}, state, { config: payload });
    default:
      return state;
  }
};

export default earningsReducer;
