import axios from "axios";

const baseUrl = process.env.REACT_APP_CARS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");
interface configBody {
  country_code: string;
  region_code: string;
  normal_driver_comission: number;
  premium_driver_comission: number;
  cancelled_ride_comission: number;
  community_driver_comission_within_six_months: number;
  community_driver_comission_after_six_months: number;
  driver_ride_percentage: number;
  shared_ride_cancellation_charges: number;
  cancelled_shared_ride_comission: number;
}

interface DeliveryConfigBody {
  country_code: string;
  region_code: string;
  normal_driver_comission: number;
  premium_driver_comission: number;
  cancelled_ride_comission: number;
  community_driver_comission_within_six_months: number;
  community_driver_comission_after_six_months: number;
  driver_ride_percentage: number;
}

export const getCountries = async () => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/countries`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getCities = async (country: string) => {
  console.log("country:", country);
  let response;
  await axios
    .get(`${baseUrl}/api/v1/country/cities/${country}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getConfigs = async () => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/earning`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const configById = async (id: any) => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/earning/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updateConfig = async (id: any, body: configBody) => {
  let response: any;
  await axios
    .put(`${baseUrl}/api/v1/earning/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getDeliveryConfigs = async () => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/earning_delivery`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getDeliveryConfigById = async (id: any) => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/earning_delivery/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updateDeliveryConfig = async (id: any, body: DeliveryConfigBody) => {
  let response: any;
  await axios
    .put(`${baseUrl}/api/v1/earning_delivery/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
