import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  TextField,
  Button,
  FormControl,
  FormLabel,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

export interface DOB {
  day: number;
  month: number;
  year: number;
}
export interface Address {
  country: string;
  state: string;
  city: string;
  postal_code: string;
  line_1: string;
}
export interface DriverDetailsPayload {
  date_of_birth: DOB;
  address: Address;
}

interface DriverPersonalInfoModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (payload: DriverDetailsPayload) => Promise<void>;
  initialValues?: {
    date_of_birth?: { day: number; month: number; year: number };
    address?: Address;
    countryCode?: string;
  };
}
const countryCityMapping: Record<string, { countryName: string; cityName: string }> = {
  GB: {
    countryName: "United Kingdom",
    cityName: "London Boroughs",
  },
  FR: {
    countryName: "France",
    cityName: "Ile de France",
  },
  CI: {
    countryName: "Ivory Coast",
    cityName: "Abidjan",
  },
};
// Mapping for countryCode to country value
const countryCodeToCountry: Record<string, string> = {
  uk: "GB",
  france: "FR",
  "ivory-coast": "CI",
};
const DriverPersonalInfoModal: React.FC<DriverPersonalInfoModalProps> = ({
  open,
  onClose,
  onSave,
  initialValues,
}) => {
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const [address, setAddress] = useState<Address>({
    country: "",
    state: "",
    city: "",
    postal_code: "",
    line_1: "",
  });
  const [countryCode, setCountryCode] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (initialValues?.date_of_birth) {
      const { day, month, year } = initialValues.date_of_birth;
      setDateOfBirth(dayjs(`${year}-${month}-${day}`));
    }
    if (initialValues?.address) {
      setAddress(initialValues.address);
    }
    if (initialValues?.countryCode) {
      setCountryCode(initialValues.countryCode);
    }
  }, [initialValues]);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const selectedCountry = event.target.value;
    setAddress({
      ...address,
      country: selectedCountry,
      city: countryCityMapping[selectedCountry]?.cityName || "",
    });
    setErrors((prev) => ({ ...prev, country: "" })); // Clear error
  };

  const validate = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    if (!dateOfBirth) {
      newErrors.dateOfBirth = "Date of Birth is required";
    } else if (dayjs().diff(dateOfBirth, "years") < 18) {
      newErrors.dateOfBirth = "Driver must be at least 18 years old";
    }

    if (!address.country) {
      newErrors.country = "Country is required";
    }
    if (!address.state) {
      newErrors.state = "State is required";
    }
    if (!address.city) {
      newErrors.city = "City is required";
    }
    if (address.country !== "CI" && !address.postal_code) {
      newErrors.postal_code = "Postal Code is required";
    }
    if (!address.line_1) {
      newErrors.line_1 = "Address Line 1 is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      const payload: DriverDetailsPayload = {
        date_of_birth: {
          day: dateOfBirth?.date() || 0,
          month: dateOfBirth?.month()! + 1 || 0,
          year: dateOfBirth?.year() || 0,
        },
        address,
      };
      onSave(payload);
      setSnackbarMessage("Details saved successfully!");
      setSnackbarOpen(true);
      onClose();
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Driver Details
          </Typography>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Date of Birth</FormLabel>
            <DatePicker
              value={dateOfBirth}
              onChange={(newValue) => {
                setDateOfBirth(newValue);
                setErrors((prev) => ({ ...prev, dateOfBirth: "" })); // Clear error
              }}
            />
            {errors.dateOfBirth && (
              <Typography color="error" variant="caption">
                {errors.dateOfBirth}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Country</FormLabel>
            <Select value={address.country} onChange={handleCountryChange} displayEmpty>
              {countryCode && countryCodeToCountry[countryCode] && (
                <MenuItem key={countryCode} value={countryCodeToCountry[countryCode]}>
                  {countryCityMapping[countryCodeToCountry[countryCode]].countryName}
                </MenuItem>
              )}
            </Select>
            {errors.country && (
              <Typography color="error" variant="caption">
                {errors.country}
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>City</FormLabel>
            <TextField
              value={address.city}
              fullWidth
              onChange={(e) => {
                setAddress({ ...address, city: e.target.value });
                setErrors((prev) => ({ ...prev, city: "" })); // Clear error
              }}
            />
            {errors.city && (
              <Typography color="error" variant="caption">
                {errors.city}
              </Typography>
            )}
          </FormControl>
          <TextField
            label="State"
            value={address.state}
            onChange={(e) => {
              setAddress({ ...address, state: e.target.value });
              setErrors((prev) => ({ ...prev, state: "" })); // Clear error
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          {errors.state && (
            <Typography color="error" variant="caption">
              {errors.state}
            </Typography>
          )}
          <TextField
            label="Postal Code"
            value={address.postal_code}
            onChange={(e) => {
              setAddress({ ...address, postal_code: e.target.value });
              setErrors((prev) => ({ ...prev, postal_code: "" })); // Clear error
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          {errors.postal_code && (
            <Typography color="error" variant="caption">
              {errors.postal_code}
            </Typography>
          )}

          <TextField
            label="Address Line 1"
            value={address.line_1}
            onChange={(e) => {
              setAddress({ ...address, line_1: e.target.value });
              setErrors((prev) => ({ ...prev, line_1: "" })); // Clear error
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          {errors.line_1 && (
            <Typography color="error" variant="caption">
              {errors.line_1}
            </Typography>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DriverPersonalInfoModal;
