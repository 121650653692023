import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Typography,
  Paper,
} from "@mui/material";
import { userService } from "../../apis/users";
import { User, UserResponse, VtcStatus } from "../../types/users";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import { useNavigate } from "react-router-dom";
import { formatTextToCapitalise, formatTime } from "../../utils/util";
import { jwtDecode } from "jwt-decode";

const DriverList: React.FC = () => {
  const [drivers, setDrivers] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
  // Filter states
  const [status, setStatus] = useState<string | "">("all");
  const [driverType, setDriverType] = useState<string | "">("all");
  const [carType, setCarType] = useState<string | "">("all");
  const [minRejectedRides, setMinRejectedRides] = useState<number | "">("");
  const [maxRejectedRides, setMaxRejectedRides] = useState<number | "">("");
  const [minTotalRides, setMinTotalRides] = useState<number | "">("");
  const [maxTotalRides, setMaxTotalRides] = useState<number | "">("");
  const [country, setCountry] = useState<string | "">("all");
  const [city, setCity] = useState<string | "">("all");
  const [driverPlan, setDriverPlan] = useState<string | "">("all");
  const [filtersVisible, setFiltersVisible] = useState(false); // State to toggle filters visibility
  const navigate = useNavigate();
  const [adminCountry, setAdminCountry] = useState<string>("all");

  const loadDrivers = async (currentPage: number, limit: number, adminCountryLocal: string) => {
    try {
      let tempCountry = country;
      if (adminCountryLocal != "all") {
        tempCountry = adminCountryLocal;
      }
      const data: UserResponse = await userService.getUsers({
        userType: "driver",
        search,
        page: currentPage + 1, // API expects 1-based page index
        limit,
        status: status === "all" ? "" : status,
        driverType: driverType === "all" ? "" : driverType,
        carType: carType === "all" ? "" : carType,
        minRejectedRides,
        maxRejectedRides,
        minTotalRides,
        maxTotalRides,
        country:
          tempCountry === "all"
            ? ""
            : tempCountry === "uk"
            ? "GB"
            : tempCountry === "france"
            ? "FR"
            : tempCountry === "ivory-coast"
            ? "CI"
            : "",
        city: city === "all" ? "" : city,
        driverPlan: driverPlan === "all" ? "" : driverPlan,
      });
      setDrivers(data.users);
      setTotal(data.total);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setAdminCountry(decoded.countryCode);
      loadDrivers(page, rowsPerPage, decoded.countryCode);
    }
  }, [
    page,
    rowsPerPage,
    search,
    status,
    driverType,
    carType,
    minRejectedRides,
    maxRejectedRides,
    minTotalRides,
    maxTotalRides,
    country,
    city,
    driverPlan,
  ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleFilterChange =
    (setter: React.Dispatch<React.SetStateAction<any>>) => (value: any) => {
      setter(value);
      setPage(0); // Reset to the first page when a filter changes
    };
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    navigate(`/driver-documentation/${id}`);
  };
  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: 20 }}>Drivers</Typography>
            </div>
            {/* Toggle Filters Button */}
            <Button
              variant="outlined"
              onClick={() => setFiltersVisible((prev) => !prev)}
              sx={{ marginBottom: 2 }}
            >
              {filtersVisible ? "Hide Filters" : "Show Filters"}
            </Button>
            {/* Filters section */}
            {filtersVisible && (
              <Box>
                <TextField
                  label="Search Drivers by name, email & phone number"
                  value={search}
                  onChange={handleSearchChange}
                  fullWidth
                />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 2,
                    marginBottom: 2,
                    marginTop: 2,
                  }}
                >
                  {/* Status Filter */}
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleFilterChange(setStatus)(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="approved">Approved</MenuItem>
                      <MenuItem value="document_verified">Document Verified</MenuItem>
                      <MenuItem value="bank_details_added">Bank Details Added</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Driver Type Filter */}
                  <FormControl fullWidth>
                    <InputLabel>Driver Type</InputLabel>
                    <Select
                      value={driverType}
                      onChange={(e) => handleFilterChange(setDriverType)(e.target.value)}
                      label="Driver Type"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="normal">Normal</MenuItem>
                      <MenuItem value="professional">Professional</MenuItem>
                      <MenuItem value="delivery">Delivery</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Car Type Filter */}
                  <FormControl fullWidth>
                    <InputLabel>Car Type</InputLabel>
                    <Select
                      value={carType}
                      onChange={(e) => handleFilterChange(setCarType)(e.target.value)}
                      label="Vehicle Type"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="CLASSIC">Classic</MenuItem>
                      <MenuItem value="GREEN">Green</MenuItem>
                      <MenuItem value="VAN">Van</MenuItem>
                      <MenuItem value="MOTOR_BIKE">Motor Bike</MenuItem>
                      <MenuItem value="TRIKE">Trike/Van...</MenuItem>
                      <MenuItem value="MEDIUM_TRUCK">{"Medium Truck (< 1.5 ton)"}</MenuItem>
                      <MenuItem value="LARGE_TRUCK">{"Large Truck (> 1.5 Ton)"}</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Min & Max Rejected Rides */}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      label="Min Rejected Rides"
                      value={minRejectedRides}
                      onChange={(e) =>
                        handleFilterChange(setMinRejectedRides)(
                          e.target.value ? +e.target.value : ""
                        )
                      }
                      type="number"
                      fullWidth
                    />
                    <TextField
                      label="Max Rejected Rides"
                      value={maxRejectedRides}
                      onChange={(e) =>
                        handleFilterChange(setMaxRejectedRides)(
                          e.target.value ? +e.target.value : ""
                        )
                      }
                      type="number"
                      fullWidth
                    />
                  </Box>

                  {/* Min & Max Total Rides */}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      label="Min Total Rides"
                      value={minTotalRides}
                      onChange={(e) =>
                        handleFilterChange(setMinTotalRides)(e.target.value ? +e.target.value : "")
                      }
                      type="number"
                      fullWidth
                    />
                    <TextField
                      label="Max Total Rides"
                      value={maxTotalRides}
                      onChange={(e) =>
                        handleFilterChange(setMaxTotalRides)(e.target.value ? +e.target.value : "")
                      }
                      type="number"
                      fullWidth
                    />
                  </Box>

                  {/* Country Filter */}
                  {adminCountry === "all" && (
                    <FormControl fullWidth>
                      <InputLabel>Country</InputLabel>
                      <Select
                        value={country}
                        onChange={(e) => handleFilterChange(setCountry)(e.target.value)}
                        label="Country"
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="uk">UK</MenuItem>
                        <MenuItem value="france">France</MenuItem>
                        <MenuItem value="ivory-coast">Ivory Coast</MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {/* City Filter */}
                  {/* <FormControl fullWidth>
                    <InputLabel>City</InputLabel>
                    <Select value={city} onChange={(e) => setCity(e.target.value)} label="City">
                      <MenuItem value="all">All</MenuItem>
                      {country === "uk" && (
                        <MenuItem value="london-boroughs">London Boroughs</MenuItem>
                      )}
                      {country === "france" && (
                        <MenuItem value="ile-de-france">Ile-de-France</MenuItem>
                      )}
                      {country === "ivory-coast" && <MenuItem value="abidjan">Abidjan</MenuItem>}
                    </Select>
                  </FormControl> */}

                  {/* Driver Plan Filter */}
                  <FormControl fullWidth>
                    <InputLabel>Driver Plan</InputLabel>
                    <Select
                      value={driverPlan}
                      onChange={(e) => handleFilterChange(setDriverPlan)(e.target.value)}
                      label="Driver Plan"
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="is_premium">Premium</MenuItem>
                      <MenuItem value="is_community">Community</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}

            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Total Rides</TableCell>
                    <TableCell>Rejected Rides</TableCell>
                    <TableCell>Country</TableCell>
                    {/* <TableCell>City</TableCell> */}
                    <TableCell>Driver Type</TableCell>
                    <TableCell>Shared Ride Enabled</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Driver Plan</TableCell>
                    <TableCell>Car Type</TableCell>
                    <TableCell>Rating</TableCell>
                    <TableCell>Signed up at</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drivers.map((driver) => {
                    return (
                      <TableRow
                        key={driver._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        hover
                        onClick={(event) => handleClick(event, driver._id)}
                      >
                        <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                        <TableCell>{driver.email}</TableCell>
                        <TableCell>{driver.phone || "-"}</TableCell>
                        <TableCell>{driver.totalRideCount || 0}</TableCell>
                        <TableCell>{driver.rejectedRideCount || 0}</TableCell>
                        <TableCell>{driver.address?.country || "-"}</TableCell>
                        {/* <TableCell>{driver.address?.city || "-"}</TableCell> */}
                        <TableCell>{driver.driverType || "Normal"}</TableCell>
                        <TableCell>{driver.accept_shared_rides ? "Yes" : "No"}</TableCell>
                        <TableCell>{formatTextToCapitalise(driver.vtcStatus)}</TableCell>
                        <TableCell>
                          {driver.is_premium_vtc
                            ? "Premiun"
                            : driver.is_community_vtc
                            ? "Community"
                            : "Normal"}
                        </TableCell>
                        <TableCell>
                          {driver.address?.country === "CI" && driver.carType === "GREEN"
                            ? "COMFORT"
                            : driver.carType
                            ? driver.carType
                            : "-"}
                        </TableCell>
                        <TableCell>{driver.rating}</TableCell>
                        <TableCell>{formatTime(driver.createdAt)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={total}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DriverList;
