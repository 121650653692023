import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { getDeliveryConfigById, updateDeliveryConfig } from "../../apis/earnings";
import { Snackbar, Alert } from "@mui/material";

const EditEarningDelivery = () => {
  const { config } = useSelector((state: any) => state.earningsDeliveryReducer);
  const [earningData, setEarningData] = useState(config);
  const navigate = useNavigate();
  let params = useParams();
  const earningId: string | undefined = params?.id;
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  //Function to handle the input fields
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEarningData({ ...earningData, [event.target.name]: event.target.value });
  };

  // Function to update the earning's configs
  const updateEarning = async () => {
    let payload = {
      country_code: earningData.country_code,
      region_code: earningData.region_code,
      normal_driver_comission: earningData.normal_driver_comission,
      premium_driver_comission: earningData.premium_driver_comission,
      driver_ride_percentage: earningData.driver_ride_percentage,
      cancelled_ride_comission: earningData.cancelled_ride_comission,
      ride_cancellation_charges: earningData.ride_cancellation_charges,
      community_driver_comission_within_six_months:
        earningData.community_driver_comission_within_six_months,
      community_driver_comission_after_six_months:
        earningData.community_driver_comission_after_six_months,
    };
    if (
      (payload.driver_ride_percentage && payload.driver_ride_percentage > 100) ||
      payload.driver_ride_percentage < 1
    ) {
      setStatusMessage("Driver ride Percentage must be between 1 and 100");
      openSnackBar();
      return;
    }
    if (
      (payload.normal_driver_comission && payload.normal_driver_comission > 100) ||
      payload.normal_driver_comission < 1
    ) {
      setStatusMessage("All Commissions must be between 1 and 100");
      openSnackBar();
      return;
    }
    if (
      (payload.premium_driver_comission && payload.premium_driver_comission > 100) ||
      payload.premium_driver_comission < 1
    ) {
      setStatusMessage("All Commissions must be between 1 and 100");
      openSnackBar();
      return;
    }
    if (
      (payload.cancelled_ride_comission && payload.cancelled_ride_comission > 100) ||
      payload.cancelled_ride_comission < 1
    ) {
      setStatusMessage("All Commissions must be between 1 and 100");
      openSnackBar();
      return;
    }
    if (
      (payload.community_driver_comission_within_six_months &&
        payload.community_driver_comission_within_six_months > 100) ||
      payload.community_driver_comission_within_six_months < 1
    ) {
      setStatusMessage("All Commissions must be between 1 and 100");
      openSnackBar();
      return;
    }

    let res = await updateDeliveryConfig(earningId, payload);
    if (res?.status) {
      navigate("/delivery-earnings-and-management");
    }
  };
  const openSnackBar = () => {
    setOpen(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };
  // Function to fetch and update the earning configs
  const getConfigById = async (id: any) => {
    const data = await getDeliveryConfigById(id);
    setEarningData(data);
  };

  useEffect(() => {
    const id = earningId !== undefined ? earningId : "";
    getConfigById(id);
    console.log("config data " + JSON.stringify(config));
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
                Update Delivery Earning
              </Typography>

              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box
                  style={{
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Enter the percentage between 1 to 100"
                        label="Driver delivery percentage"
                        name="driver_ride_percentage"
                        variant="outlined"
                        value={earningData?.driver_ride_percentage}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Normal Driver Comission"
                        label="Normal Driver Comission"
                        name="normal_driver_comission"
                        variant="outlined"
                        value={earningData?.normal_driver_comission}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Premium Driver Comission"
                        label="Premium Driver Comission"
                        name="premium_driver_comission"
                        variant="outlined"
                        value={earningData?.premium_driver_comission}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Community Driver Comission Within Six Months"
                        label="Community Driver Comission Within Six Months"
                        name="community_driver_comission_within_six_months"
                        variant="outlined"
                        value={earningData?.community_driver_comission_within_six_months}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Community Driver Comission After Six Months"
                        label="Community Driver Comission After Six Months"
                        name="community_driver_comission_after_six_months"
                        variant="outlined"
                        value={earningData?.community_driver_comission_after_six_months}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Cancelled Delivery Comission"
                        label="Cancelled Delivery Comission"
                        name="cancelled_ride_comission"
                        variant="outlined"
                        value={earningData?.cancelled_ride_comission}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>

                    <Grid item xs={4} md={6}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Delivery Cancellation Charges"
                        label="Delivery Cancellation Charges"
                        name="ride_cancellation_charges"
                        variant="outlined"
                        value={earningData?.ride_cancellation_charges}
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItem: "center",
                  }}
                >
                  <Button
                    style={{ marginRight: "4px", backgroundColor: "gray" }}
                    variant="contained"
                    onClick={() => {
                      navigate("/delivery-earnings-and-management");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={updateEarning}>
                    Update
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItem: "center",
                  }}
                >
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={closeSnackBar}
                  >
                    <Alert
                      onClose={closeSnackBar}
                      severity="error"
                      sx={{ width: "100%", alignItems: "center" }}
                    >
                      {statusMessage}
                    </Alert>
                  </Snackbar>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditEarningDelivery;
